<template>
  <div class="">
    <v-card :loading="loading" :disabled="loading" loader-height="2">
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <v-btn small color="primary" class="mr-1" @click="addEditForm()">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>New</span>
          </v-btn>
          <bulkChartOfAccountUpload @reload="refreshData()"/>
          <v-btn class="shadow-off" text @click="refreshData()" small icon><v-icon>mdi-refresh</v-icon></v-btn>
        </div>
        <v-data-table
          dense
          :headers="tableheader"
          :items="listdata"
          :items-per-page="-1"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.preparer`]="{ item }">
            <div class="d-flex justify-center" v-if="(item.preparer || []).length > 0">
              <v-tooltip bottom content-class="tooltip-bottom" v-for="(vv,kk) in item.preparer.slice(0, 2)" :key="kk">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="30" class="mr-1" :style="`background-color:${(userlist[vv] || {}).color || 'rgba(0,0,0,0.2)'}`" v-bind="attrs" v-on="on">
                    {{(((userlist[vv] || {}).name || "U N").split(" ") || []).slice(0, 2).map((word) => word[0].toUpperCase()).join("")}}
                  </v-avatar>
                </template>
                <span>{{((userlist[vv] || {}).name || "Unknown User")}}</span>
              </v-tooltip>
              <v-avatar size="30" v-if="item.preparer.length > 2" class="mr-1 border-on">
                +{{item.preparer.length - 2}}
              </v-avatar>
            </div>
          </template>
          <template v-slot:[`item.approver`]="{ item }">
            <div class="d-flex justify-center" v-if="(item.approver || []).length > 0">
              <v-tooltip bottom content-class="tooltip-bottom" v-for="(vv,kk) in item.approver.slice(0, 2)" :key="kk">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="30" class="mr-1" :style="`background-color:${(userlist[vv] || {}).color || 'rgba(0,0,0,0.2)'}`" v-bind="attrs" v-on="on">
                    {{(((userlist[vv] || {}).name || "U N").split(" ") || []).slice(0, 2).map((word) => word[0].toUpperCase()).join("")}}
                  </v-avatar>
                </template>
                <span>{{((userlist[vv] || {}).name || "Unknown User")}}</span>
              </v-tooltip>
              <v-avatar size="30" v-if="item.approver.length > 2" class="mr-1 border-on">
                +{{item.approver.length - 2}}
              </v-avatar>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight(right, 'edit')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click="addEditForm(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="item.status && $nova.hasRight(right, 'enabledisable')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="error" v-bind="attrs" v-on="on" @click="enableDisableAccess(item._id, 'disable')">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-else-if="!item.status && $nova.hasRight(right, 'enabledisable')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="success" v-bind="attrs" v-on="on" @click="enableDisableAccess(item._id, 'enable')">
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <lb-dialog v-model="addeditdialog" :heading="addeditdata._id ? 'Update' : 'Add New'" width="600" :loading="addeditloading">
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="6" class="my-0 py-1">
              <lb-string v-model="addeditdata.glcode" label="GL Code*" :error="addeditdataerror.glcode"/>
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-dropdown v-model="addeditdata.type" label="Type*" :error="addeditdataerror.type" :items="possibletypes"/>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string v-model="addeditdata.name" label="Name*" :error="addeditdataerror.name"/>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string v-model="addeditdata.category" label="Category*" :error="addeditdataerror.category"/>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string v-model="addeditdata.subcategory" label="Sub Category*" :error="addeditdataerror.subcategory"/>
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-dropdown v-model="addeditdata.preparer" :items="formdata.users" itemtext="name" itemvalue="_id" multiple label="Preparer(s)" :error="addeditdataerror.preparer"/>
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-dropdown v-model="addeditdata.approver" :items="formdata.users" itemtext="name" itemvalue="_id" multiple label="Approver(s)" :error="addeditdataerror.approver"/>
            </v-col>
        </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addEditItem()">
          <span v-if="addeditdata._id">Update</span>
          <span v-else>Add</span>
        </v-btn>
      </template>
    </lb-dialog>
  </div>
</template>

<script>
import bulkChartOfAccountUpload from '../../views/Components/BulkUploadChartOfAccount.vue';

export default {
  name: 'fluxmanagement_master_chartofaccount',
  // props: ['props'],
  data: function(){
    return {
      right: "fluxmanagement_master_chartofaccount",
      // generateddescription: "",
      tableheader: [
        { text: 'GL Code', value: 'glcode' },
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Category', value: 'category' },
        { text: 'Sub Category', value: 'subcategory' },
        { text: 'Preparer(s)', value: 'preparer', sortable: false },
        { text: 'Approver(s)', value: 'approver', sortable: false },
        { text: 'Action', value: 'action', align: "center", sortable: false },
      ],
      possibletypes: ['Asset', 'Equity', 'Liability', 'Income', 'Expense', 'Others'],
      addeditdata: {},
      addeditdataerror: {},
      addeditdialog: false,
      checklist: [],
      addeditloading: false,
      loading: false,
      listdata: [],
      userlist: {},
      formdata: {},
    }
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  components: {
    bulkChartOfAccountUpload
  },
  methods: {
    init(){
      this.refreshData();
    },
    refreshData() {
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/fluxmanagement/masters/chartofaccount/formdata").then(dt => {
        if(dt.data.status === "success"){
          this.formdata = dt.data.data[0] || {};
          // this.checklist = this.formdata.checklist || [];
          this.userlist = {};
          let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
          console.log(this.formdata);
          for (let i = 0; i < (this.formdata.users || []).length; i++) {
            const el = (this.formdata.users || [])[i];
            this.userlist[el._id] = el;
            this.userlist[el._id].color = colors[i];            
          }
          return this.axios.post("/v2/fluxmanagement/masters/chartofaccount/get")
        }else throw new Error("Error reading form data");
      }).then(dt => {
        this.listdata = [];
        if(dt.data.status === "success"){
          this.listdata = dt.data.data;
        }else throw new Error(dt.data.message);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      }).finally(() => {
        this.loading = false;
      })
    },
    addEditForm(dt={}){
      this.addeditdata = {...dt};
      this.addeditdataerror = {};
      this.addeditdialog = true;
    },
    addEditItem() {
      this.addeditloading = true;
      let type = "add";
      let url = "/v2/fluxmanagement/masters/chartofaccount/add";
      if(this.addeditdata._id) {
        type = "edit";
        url = "/v2/fluxmanagement/masters/chartofaccount/edit/"+this.addeditdata._id;
      }
      let ndt = {...this.addeditdata};
      delete ndt._id;
      this.axios.post(url, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          if(type === "add") this.$store.commit("sbSuccess", "Checklist item added");
          else this.$store.commit("sbSuccess", "Checklist item updated");
          this.addeditdialog = false;
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditdataerror = dt.data.data[0].index0;
          }else throw new Error (dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditloading = false;
      })
    },
    enableDisableAccess(id, action) {
      this.loading = true;
      let url = "/v2/fluxmanagement/masters/chartofaccount/disable/";
      if(action === "enable"){
        url = "/v2/fluxmanagement/masters/chartofaccount/enable/"
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if(action === "enable") {
              this.$store.commit("sbSuccess", "Item enabled");
              // this.listdata[k].status = true;
            }
            else{
              this.$store.commit("sbSuccess", "Item disabled");
              // this.listdata[k].status = false;
            } 
            this.refreshData();
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
  }
}
</script>
